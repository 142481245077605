// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyDmci7Z0n84cQ8NoXX4lHn6ev4SIu9x5sE",
    authDomain: "ai-projects-375616.firebaseapp.com",
    projectId: "ai-projects-375616",
    storageBucket: "ai-projects-375616.appspot.com",
    messagingSenderId: "949282308997",
    appId: "1:949282308997:web:6969b0e70c07c2cddefaec",
    measurementId: "G-CD8ZRJRXXR"
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
