import './App.css';
import { useRef, useState, useEffect  } from "react"
import RECAPTCHA from "react-google-recaptcha"
import { useCookies } from 'react-cookie';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom'
import {  signOut } from "firebase/auth";

const VisionComparator = () => {

  // drag state
  const ALLOWED_FREE_USES = 3;
  const [dragActive, setDragActive] = useState(false);
  const [image, setImage] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isRecaptchaNeeded, setIsRecaptchaNeeded] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [AIData, setAIData] = useState(null);
  const [cookies, setCookie] = useCookies(['UserCount']);
  const [aiservice, setAiservice] = useState("amazon")
  const [AIitems, setAIItems] = useState({});
  const navigate = useNavigate();

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setFirebaseUser(user);
            } else {
                setFirebaseUser(null);
            }
        });
    }, [])

    const handleLogout = () => {               
        signOut(auth).then(() => {
        // Sign-out successful.
            setFirebaseUser(null);
            navigate("/");
        }).catch((error) => {
        // An error happened.
        });
    }

  // Function to add an item to the array
  const setAIItem = (key, value) => {
    setAIItems((prevItems) => {
      return { ...prevItems, [key]: value };
    });
  };
  const getAIItem = (key) => {
    return AIitems[key];
  };
  const clearAIItems = () => {
    setAIItems({});
  };
  
  // ref
  const inputRef = useRef(null);
  const captchaRef = useRef(null)
  
  
  const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"];

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = async function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      await handleFile(e.dataTransfer.files[0]);
    }
  };
  
  // triggers when file is selected with click
  const handleInputFile = async function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      await handleFile(e.target.files[0]);
    }
  };
  
  // triggers the input when the button is clicked
  const onInputButtonClick = () => {
    inputRef.current.click();
  };

  const IsUserAuthenticated = (user) => {
    if(cookies.UserCount >= ALLOWED_FREE_USES && user == null)
    {
        navigate("/login");
        return false;
    }
    return true;
  }

  
  function rescaleImage(file, desiredWidth) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
  
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          const aspectRatio = image.width / image.height;
          const desiredHeight = desiredWidth / aspectRatio;
  
          canvas.width = desiredWidth;
          canvas.height = desiredHeight;
  
          ctx.drawImage(image, 0, 0, desiredWidth, desiredHeight);
  
          canvas.toBlob((blob) => {
            const rescaledFile = new File([blob], file.name, { type: file.type });
            resolve(rescaledFile);
          }, file.type);
        };
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsDataURL(file);
    });
  }
  
  
  const handleFile= async function(file) {
    let isValid = acceptedFiles.includes(file.type);
    if (!isValid){
      return;
    }
    const rescaledFile = await rescaleImage(file, 500);
    var userCount = cookies.UserCount === undefined ? 0 : cookies.UserCount;
    userCount++;
    setCookie('UserCount', userCount, { path: '/' });
    clearData();
    setImage(rescaledFile);
    setIsImageLoaded(true);
    callAIService(false, aiservice, rescaledFile, firebaseUser, true);
  };

  const callAIService = async (useMemoryFile, service, imageFile, user, validateRecaptcha) => {
    if(imageFile == null)
    {
        return;
    }

    const processResponseCallback = function(service, data) 
    {
      setAIItem(service, data);
      setAIData(data);
    }

    if(useMemoryFile)
    {
      let aiItem = getAIItem(service);
      if(aiItem != null)
      {
        processResponseCallback(service, aiItem);
        return;
      }
    }

    IsUserAuthenticated(user);

    var askForRechaptcha = validateRecaptcha && cookies.UserCount % ALLOWED_FREE_USES == 0;
    if (askForRechaptcha)
    {
        setIsRecaptchaNeeded(true);
        return;
    }

    const formData = new FormData();
    formData.append('file', imageFile);
    const requestOptions = {
      method: 'POST',
      headers: {
        service: service
      },
      body: formData
    };

    const response = await fetch(process.env.REACT_APP_VISION_AI_COMPARATION_FUNCTION_APP, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((response) => processResponseCallback(service, response));
        } else if (response.status === 401) {
           setIsRecaptchaNeeded(true);
        } else {
          throw new Error(`Request failed with status ${response.status}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const onRecaptchaChange= e => {
    var recaptchaToken = captchaRef.current.getValue();
    captchaRef.current.reset();
    setIsRecaptchaNeeded(false);
    callAIService(false, aiservice, image, firebaseUser, false);
  }

  const clearData = function(){
    setImage(null);
    setAIData(null);
    setIsImageLoaded(false);
    clearAIItems();
    if(captchaRef != null && captchaRef.current != null)
    {
      captchaRef.current.reset();
    }
  }

  function VisionServiceSelector() {
    const onAIServiceChange = e => {
      e.preventDefault();
      if(isRecaptchaNeeded)
      {
        return;
      }
      setAiservice(e.target.value)
      if(image != null)
      {
        setAIData(null);
        callAIService(true, e.target.value, image, firebaseUser, true);
      }
    }

    return (
      <div className="horizontal-panel">
        <h1>Select the AI Vision API</h1>
        <div className="row">
          <div className="column">
            <div className="item">
              <label>
                <input type="radio" name="visionService" value="google" checked={aiservice === "google"} onChange={onAIServiceChange}/>
                <img src="./logos/aiservices/google.png" alt="Google" className="center"/>
              </label>
            </div>
          </div>
          <div className="column">
            <div className="item">
              <label>
                <input type="radio" name="visionService" value="amazon" checked={aiservice === "amazon"} onChange={onAIServiceChange}/>
                <img src="./logos/aiservices/aws.png" alt="AWS" className="center"/>
              </label>
            </div>
          </div>
          <div className="column">
            <div className="item">
              <label>
                <input type="radio" name="visionService" value="azure" checked={aiservice === "azure"} onChange={onAIServiceChange}/>
                <img src="./logos/aiservices/azure.png" alt="Azure" className="center"/>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ImageLoader() {
    return (
      <div onClick={(e) => IsUserAuthenticated(firebaseUser)}>
        {!isImageLoaded && <div className="horizontal-panel">
          <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input ref={inputRef} type="file" accept={acceptedFiles} id="input-file-upload" multiple={false} onChange={handleInputFile} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
              <div>
                <p>Drag image file or</p>
                <button className="upload-button" onClick={onInputButtonClick}>Browse from your computer</button>
              </div> 
            </label>
            { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
          </form>
        </div>}
      </div>
    );
  }

  function ImageProcessor(){
    const newFileRef = useRef(null);

    const onNewFile = (event) => {
      event.preventDefault();
      if(IsUserAuthenticated(firebaseUser))
      {
        newFileRef.current.click();
      }
    };

    const onReset = function(event) {
      event.preventDefault();
      clearData();
    }

    function CanvasWithRectangles(props) {
      const canvasRef = useRef(null);
      const { imageSrc, data } = props;
    
      useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          if(data != null)
          {
            drawRectangles(image, context, data.formattedData);
          }
        };
        image.src = imageSrc;
      }, [imageSrc, data]);
    
      const drawRectangles = (image, context, objects) => {
        context.lineWidth = 2;
        context.strokeStyle = '#79FF31';
        context.fillStyle = '#79FF31';
        let fontSize = 11;
        context.font = 'bold '+fontSize+'px sans-serif';
        for (let i = 0; i < objects.length; i++) {
          const object = objects[i];
          context.strokeRect(object.rectangle.x, object.rectangle.y, object.rectangle.w, object.rectangle.h);
          context.fillText(object.name + " " + object.confidence.toFixed(2) + "%", object.rectangle.x, object.rectangle.y + (12));
        }
      };

      return (
        <canvas ref={canvasRef} />
      );
    }

    return(
      <div className="image-processor">
          {isImageLoaded && <div className={AIData != null ? "image-processor-container" : ""}>
          {image && (
              <div className={AIData != null ? "image-processor-container-top-left" : ""}>
                <div className="image">
                  <CanvasWithRectangles imageSrc={URL.createObjectURL(image)} data={AIData} />
                  {AIData == null && !isRecaptchaNeeded && (<div className="loading-spinner"></div>)}
                </div>
              </div>
          )}

          <div className="image-processor-container-top-right">
            {AIData != null && <div className="objectTags">
              {AIData.formattedData.map((obj, index) => (
                    <div className="object-item" key={index}>
                      <div className="object-item-info">
                        <span className="object-item-name">{obj.name}</span>
                        <span className="object-item-percent">{`${obj.confidence.toFixed(2)}%`}</span>
                      </div>
                      <div className="object-item-progress">
                        <div className="object-item-progress-bar" style={{width: `${obj.confidence}%`}}></div>
                      </div>
                    </div>
                  ))}  
            </div>}    
                  
          </div>        

          <div className="image-processor-container-bottom-left">
            {AIData != null && <div className="wrap-collapsible">
                <input id="collapsible" className="toggle" type="checkbox"></input>
                <label htmlFor="collapsible" className="lbl-toggle">Show JSON</label>
                <div className="collapsible-content">
                  <div className="content-inner">
                    <pre>{JSON.stringify(JSON.parse(AIData.rawData), null, 2)}</pre>
                  </div>
                </div>
            </div>}
          </div>  
          <div className="image-processor-container-bottom-right">
            {AIData != null && <form>
                <div className='reset-buttons'>
                  <button onClick={onReset} className = "submitButton">RESET</button>
                  <input ref={newFileRef} type="file" accept={acceptedFiles} multiple={false} onChange={handleInputFile} className="hidden"/>
                  <button onClick={onNewFile} className = "submitButton">NEW FILE</button>
                </div>
            </form>}
          </div>

        </div>}
      </div>
    );
  }

  return ( 
    <div>
      <a href='https://www.denovostudios.com/'>
        <img src={require('./logos/denovostudios.png')} alt="Denovo Studios" className="center" id="denovo-logo"/>
      </a>
      <div className="image-comparator-main-div center">
        <VisionServiceSelector></VisionServiceSelector>
        <div>
          <ImageLoader/><ImageProcessor/>
        </div> 
        <div className="horizontal-panel recaptcha" style={{ display: isRecaptchaNeeded ? 'block' : 'none' }}>
          <RECAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={() => onRecaptchaChange()} 
          style={{ display: "flex", justifyContent: "center",alignItems: "center" }}/>
        </div> 
      </div>
    </div>
  );
}

export default VisionComparator;
