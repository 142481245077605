import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';
import './style.css';
 
const Signup = () => {
    const navigate = useNavigate();
 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
 
    const onSubmit = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            navigate("/")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });
 
   
    }
 
  return (
    <main >        
        <section>
            <a href='https://www.denovostudios.com/'>
                <img src={require('../logos/denovostudios.png')} alt="Denovo Studios" className="center" id="denovo-logo"/>
            </a>
            <div>
                <section className="ftco-section">
                    <div className='container'>   
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                <div className="login-wrap p-4 p-md-5">                                                                                         
                                    <form>                                                                                    
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    label="Email address"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}  
                                                    required                                    
                                                    placeholder="Email address" 
                                                    className="form-control rounded-left"                                 
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    type="password"
                                                    label="Create password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)} 
                                                    required                                 
                                                    placeholder="Password"     
                                                    className="form-control rounded-left"         
                                                />
                                            </div>                                             
                                            
                                            <div className="form-group">
                                                <button
                                                    type="submit" 
                                                    onClick={onSubmit} 
                                                    className="form-control btn btn-primary rounded submit px-3"                       
                                                >  
                                                    Sign up                                
                                                </button>
                                            </div>                                                     
                                    </form>
                                
                                    <p>
                                        Already have an account?{' '}
                                        <NavLink to="/login" >
                                            Sign in
                                        </NavLink>
                                    </p> 
                                </div>
                            </div>
                        </div>                  
                    </div>
                </section>
            </div>
        </section>
    </main>
  )
}
 
export default Signup