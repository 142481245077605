import React, {useState} from 'react';
import {  signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import './style.css';
import GoogleButton from 'react-google-button'

const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({ prompt: 'select_account' });

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
        });
       
    }

    const onLoginWithGoogle = (e) => {
        e.preventDefault();
        signInWithPopup(auth, googleProvider)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
    }

    return (
        <main>        
            <section>
                <a href='https://www.denovostudios.com/'>
                    <img src={require('../logos/denovostudios.png')} alt="Denovo Studios" className="center" id="denovo-logo"/>
                </a>
                <section className="ftco-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-lg-5">
                                    <div className="login-wrap p-4 p-md-5"> 
                                    <div>To continue using our AI comparison services, please log in using your email address</div>                     
                                    <form>                                              
                                        <div className="form-group">
                                            <input
                                                id="email-address"
                                                name="email"
                                                type="email"                                    
                                                required                                                                                
                                                placeholder="Email address"
                                                onChange={(e)=>setEmail(e.target.value)}
                                                className="form-control rounded-left"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"                                    
                                                required                                                                                
                                                placeholder="Password"
                                                onChange={(e)=>setPassword(e.target.value)}
                                                className="form-control rounded-left"
                                            />
                                        </div>
                                                            
                                        <div className="form-group">
                                            <button onClick={onLogin} className="form-control btn btn-primary rounded submit px-3">Login</button>
                                        </div>   
                                        <div className="form-group">
                                            <GoogleButton onClick={onLoginWithGoogle}  style={{ width: "100%" }}/>
                                        </div>                           
                                    </form>

                                    <p>
                                        No account yet? {' '}
                                        <NavLink to="/signup">
                                            Sign up
                                        </NavLink>
                                    </p>                 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
 
export default Login