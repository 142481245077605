import './App.css';

import VisionComparator from './VisionComparator';
import Signup from './Authentication/SignUp';
import Login from './Authentication/Login';
import { BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';

function App() {
   return (
    <Router>
      <div>
        <section>                              
            <Routes>
               <Route path="/" element={<VisionComparator/>}/>
               <Route path="/signup" element={<Signup/>}/>
               <Route path="/login" element={<Login/>}/>
            </Routes>                    
        </section>
      </div>
    </Router>
  );
}
 
export default App;
